import React from "react";

import { Link } from "gatsby";

import { HeadingLevel, Heading } from "baseui/heading";
import { Paragraph1, Label1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Logo from "../../components/logo-frp";
import Page from "../../components/internal-page";

const About = () => {
  return (
    <Page title="О Фонде" path="/fund" parent="fund">
      <HeadingLevel>
        <Label1>Юридический и фактический адрес Фонда</Label1>
        <Paragraph1>
          163000, г. Архангельск, Набережная Северной Двины, д. 71.
        </Paragraph1>
        <Label1>Почтовый адрес</Label1>
        <Paragraph1>
          Отделение почтовой связи - 163000, г. Архангельск, ул. Воскресенская,
          д. 5, а/я №30, Фонд "МКК Развитие"
        </Paragraph1>
        <Label1>Телефоны</Label1>
        <Paragraph1>(8182) 464122, 639547</Paragraph1>
        <Label1>Электронная почта</Label1>
        <Paragraph1>expert@cmf29.ru</Paragraph1>
        <HeadingLevel>
          <Heading>Структура Фонда</Heading>
          <Label1>Учредитель Фонда</Label1>
          <Paragraph1>
            <StyledLink
              href="https://dvinaland.ru/gov/iogv/minec/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Министерство экономического развития, промышленности и науки
              Архангельской области
            </StyledLink>
            . Заместитель председателя Правительства Архангельской области,
            министр - Иконников Виктор Михайлович. Адрес: 163000, г.
            Архангельск, пр. Троицкий, д. 49, тел. (8182) 288150, 288392, факс
            (8182) 215298, электронная почта: min_econo@dvinaland.ru
          </Paragraph1>
          <Label1>Председатель Наблюдательного совета Фонда</Label1>
          <Paragraph1>
            Заборский Максим Николаевич - генеральный директор автономной
            некоммерческой организации Архангельской области "Агентство
            регионального развития"
          </Paragraph1>
          <Label1>Заместитель председателя Наблюдательного совета Фонда</Label1>
          <Paragraph1>
            Вадапалайте Эслинда – заместитель начальника управления развития
            предпринимательства, внешнеэкономической деятельности и инвестиций
            министерства экономического развития, промышленности и науки
            Архангельской области, тел. (8182) 288378, электронная почта:
            vadapalaite@dvinaland.ru
          </Paragraph1>
          <Paragraph1>
            <StyledLink
              href="https://drive.google.com/file/d/17kV6ct-zlntlizOhduDgHq3QYYykh77J/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Состав Наблюдательного совета Фонда
            </StyledLink>
          </Paragraph1>
          <Label1>Председатель Попечительского совета Фонда</Label1>
          <Paragraph1>
            Антуфьев Сергей Кимович - председатель Совета Архангельского
            областного отделения общероссийской общественной организации малого
            и среднего предпринимательства "ОПОРА РОССИИ", тел. 8-9600140000
          </Paragraph1>
          <Paragraph1>
            <StyledLink
              href="https://drive.google.com/file/d/1iuKbZ8vztDFJs69XTVmZTSJI6ousz9kP/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Состав Попечительского совета Фонда
            </StyledLink>
          </Paragraph1>
          <Label1>Исполнительный директор Фонда</Label1>
          <Paragraph1>
            Тутыгин Андрей Геннадьевич, тел. (8182) 464122 доб. 101, 431085,
            электронная почта: director@cmf29.ru. Дата назначения - 03.11.2010.
          </Paragraph1>
          <Label1>Отдел бухгалтерского учета и анализа</Label1>
          <Paragraph1>
            Главный бухгалтер – Дубинина Лариса Вениаминовна, тел. (8182) 464122
            доб. 102, электронная почта: buh@cmf29.ru
          </Paragraph1>
          <Label1>Отдел по работе с заёмщиками</Label1>
          <Paragraph1>
            Заместитель исполнительного директора - начальник отдела –
            Кострушина Надежда Сергеевна, тел. (8182)&nbsp;464122 доб. 109, 639547,
            электронная почта: expert@cmf29.ru
          </Paragraph1>
          <Label1>Организационно-правовой отдел</Label1>
          <Paragraph1>
            Начальник отдела - Коротяева Елена Владимировна, тел. (8182) 464122
            доб. 105, электронная почта: pravo@cmf29.ru
          </Paragraph1>
        </HeadingLevel>
        <HeadingLevel>
          <Heading>Регулирующие организации</Heading>
          <Paragraph1>
            Функции по регулированию, контролю и надзору в сфере микрофинансовой
            деятельности осуществляет{" "}
            <StyledLink
              href="https://www.cbr.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Банк России
            </StyledLink>
            . Колл-центр Банка России: 8-800-300-3000, 8-499-300-3000{" "}
          </Paragraph1>
          <Paragraph1>
            Отделение по Архангельской области Северо-Западного ГУ ЦБ РФ:
            163000, г. Архангельск, ул. Карла Либкнехта, д. 3, тел. (8182)
            651504
          </Paragraph1>
          <Paragraph1>
            Фонд является членом саморегулируемой организации{" "}
            <StyledLink
              href="https://alliance-mfo.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              МФО Союз "Микрофинансовый альянс "Институты развития малого и
              среднего бизнеса"
            </StyledLink>
            . Адрес: 127055, г. Москва, ул. Сущёвская, д. 21, офис 513, тел.
            8(800) 3336867, тел./факс (495) 7772203, e-mail:
            info@alliance-mfo.ru
          </Paragraph1>
        </HeadingLevel>
        <HeadingLevel>
          <Heading>Защита прав предпринимателей</Heading>
          <Label1>Прокуратура Архангельской области</Label1>
          <Paragraph1>
            "Горячая линия" : (8182) 410235.{" "}
            <StyledLink
              href="https://epp.genproc.gov.ru/web/proc_29"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://epp.genproc.gov.ru/web/proc_29
            </StyledLink>
          </Paragraph1>
          <Label1>
            Уполномоченный при Губернаторе Архангельской области по защите прав
            предпринимателей
          </Label1>
          <Paragraph1>
            163000, г. Архангельск, ул. Выучейского, д. 18, каб. 104, тел.
            (8182) 650975,{" "}
            <StyledLink
              href="https://бизнес-защитник.рф/"
              target="_blank"
              rel="noopener noreferrer"
            >
              бизнес-защитник.рф
            </StyledLink>
            , электронная почта: ombiz@dvinaland.ru
          </Paragraph1>
          <Paragraph1>
            Информация о противодействии коррупции на сайте Правительства
            Архангельской области:{" "}
            <StyledLink
              href="https://dvinaland.ru/gov/anticorr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://dvinaland.ru/gov/anticorr/
            </StyledLink>
          </Paragraph1>
          <Label1>Финансовый Уполномоченный</Label1>
          <Paragraph1>
            <StyledLink
              href="https://finombudsman.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://finombudsman.ru/
            </StyledLink>{" "}
            – финансовый уполномоченный осуществляет досудебное урегулирование
            споров между потребителями финансовых услуг и финансовыми
            организациями.
          </Paragraph1>
          <Paragraph1>
            Новый порядок урегулирования споров установлен Федеральным законом
            от 04.06.2018 г. N 123-ФЗ "Об уполномоченном по правам потребителей
            финансовых услуг".
          </Paragraph1>
          <Paragraph1>
            8 (800) 200-00-10 – понедельник - пятница с 08:00 до 20:00 (мск),
            кроме нерабочих праздничных дней. Бесплатно для звонков из регионов
            России.
          </Paragraph1>
          <Label1>Федеральная служба судебных приставов</Label1>
          <Paragraph1>
            <StyledLink
              href="https://fssp.gov.ru/form"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://fssp.gov.ru/form
            </StyledLink>{" "}
            – интернет-приёмная ФССП России.
          </Paragraph1>
        </HeadingLevel>
        <Logo />
        <Paragraph1>
          Фонд "МКК Развитие" осуществляет функции и полномочия{" "}
          <StyledLink to="/frp/about" $as={Link}>
            регионального фонда развития промышленности
          </StyledLink>
        </Paragraph1>
      </HeadingLevel>
    </Page>
  );
};

export default About;
